<template>
	<b-navbar spaced wrapper-class="container">
		<template slot="brand">
			<b-navbar-item tag="router-link" :to="{ path: '/dashboard' }">
				<img alt="moveUP" src="../assets/moveUP-symbol-green.svg" />
			</b-navbar-item>
		</template>
		<template v-if="user && user._id" slot="start">
			<b-navbar-item tag="router-link" :to="{ path: '/dashboard' }">
				<translate>Dashboard</translate>
			</b-navbar-item>
			<b-navbar-item
				v-if="access.scopes.includes('application:read')"
				tag="router-link"
				:to="{ path: '/applications' }"
			>
				<translate>Applications</translate>
			</b-navbar-item>
			<b-navbar-item tag="router-link" :to="{ path: '/users' }"> <translate>Users</translate> </b-navbar-item>
			<b-navbar-item
				tag="router-link" :to="{ path: '/codes' }"> <translate>Registration codes</translate>
			</b-navbar-item>
		</template>

		<template slot="end">
			<b-navbar-item href="https://support.orthopedics.moveup.care/" target="_blank" rel="noopener">
				<translate>Support</translate>
			</b-navbar-item>
			<b-navbar-dropdown v-if="user && user._id" :label="$gettext('Account')">
				<b-navbar-item tag="router-link" :to="{ path: '/settings' }">
					<translate>Settings</translate></b-navbar-item
				>
				<b-navbar-item tag="router-link" :to="{ path: '/logout' }">
					<translate>Log out</translate></b-navbar-item
				>
			</b-navbar-dropdown>
		</template>
	</b-navbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
	computed: {
		...mapState('auth', ['user', 'access']),
	},
};
</script>
