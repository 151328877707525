<template>
	<section class="code-edit">
		<h1 v-if="data" v-translate class="is-size-1">
			Edit registration code
		</h1>
		<form ref="form" @submit.prevent="save">
			<div v-if="data">
				<b-field grouped>
					<b-field :label="$gettext('Code')" expanded>
						<b-input v-model="data.code" name="code" required></b-input>
					</b-field>
				</b-field>
				<b-field grouped>
					<b-field :label="$gettext('Description')" expanded>
						<b-input v-model="data.description" name="description"></b-input>
					</b-field>
				</b-field>
				<b-field grouped>
					<b-field :label="$gettext('Role')" expanded>
						<b-select v-model="selectedRole" expanded required>
							<option v-for="role in allRoles" :key="role._id" :value="role._id">
								{{ role.name }}
							</option>
						</b-select>
					</b-field>
				</b-field>
				<b-field grouped>
					<b-field :label="$gettext('Platform flavor')" expanded>
						<b-select v-model="data.platformFlavor" expanded required>
							<option v-for="flavor in platformFlavors" :key="flavor._id" :value="flavor._id">
								{{ flavor.name }}
							</option>
						</b-select>
					</b-field>
				</b-field>
				<b-field :label="$gettext('Code disabled')">
					<b-switch v-model='data.disabled' name='disabled' type='is-primary'>
						{{ data.disabled ? $gettext('Yes') : $gettext('No') }}
					</b-switch>
				</b-field>
				<div v-if="access.scopes.includes('application:read')">
					<h2 v-translate class="is-size-2 mt-6">Applications</h2>
					<associate
						:name="$gettext('applications')"
						:associated="associated.applications"
						:unassociated="unassociated.applications"
						key-name="_id"
						:display-keys="['name']"
						:search-keys="['name']"
						@change="handleChangeApplications"
					></associate>

					<div v-if="associated.applications.length > 0">
						<h2 v-translate class="is-size-2 mt-6">Limit scopes</h2>
						<p v-translate class="is-size-7">
							Add scopes here if you want to limit this code's access in some applications.
						</p>
						<associate
							:name="$gettext('forbidden scopes')"
							:associated="associated.removedScopes"
							:unassociated="unassociated.removedScopes"
							associated-box-type="is-warning"
							@change="handleChangeScopes"
						></associate>
					</div>
				</div>
				<div v-if="access.scopes.includes('organization:read')">
					<h2 v-translate class="is-size-2 mt-6">Organizations</h2>
					<associate
						:name="$gettext('organizations')"
						:associated="associated.organizations"
						:unassociated="unassociated.organizations"
						associated-box-type="is-primary"
						key-name="_id"
						:display-keys="['name']"
						:search-keys="['name']"
						@change="handleChangeOrganizations"
					></associate>
				</div>

				<b-loading v-model="isLoading" :is-full-page="false"></b-loading>
			</div>
			<div class="buttons is-pulled-right mt-6">
				<b-button @click="back"><translate>Back to list</translate></b-button>
				<b-button
					v-if="data && access.scopes.includes('code:write')"
					type="is-primary"
					icon-left="content-save"
					@click="save"
				>
					<translate>Save</translate>
				</b-button>
			</div>
		</form>
	</section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { sortBy, compose, toLower, propOr, pick, difference, uniq } from 'ramda';
import { errorToast, successToast } from '@moveup/app-core/mixins';
import { Associate } from '@moveup/app-core/components';

const concat = (p, c) => p.concat(c);

export default {
	name: 'EditCode',
	components: { Associate },
	mixins: [errorToast, successToast],
	data() {
		return {
			id: undefined,
			isLoading: false,
			data: undefined,
			allApplications: [],
			allOrganizations: [],
			allScopes: [],
			allRoles: [],
			platformFlavors: [],
			associated: {
				applications: [],
				removedScopes: [],
				organizations: [],
			},
			unassociated: {
				applications: [],
				removedScopes: [],
				organizations: [],
			},
			selectedRole: undefined
		};
	},
	computed: {
		...mapState('auth', ['access']),
		missingScopes() {
			return (
				this.associated.removedScopes.length > 0 &&
				difference(
					this.associated.applications.map(app => app.scopes).reduce(concat, []),
					this.associated.removedScopes,
				)
			);
		},
	},
	mounted() {
		if (this.$route.params.id) this.id = this.$route.params.id;
		this.loadAsyncData();
	},
	methods: {
		...mapActions(
			'app', ['getCode', 'getApplications', 'getOrganizations',
				'updateCode', 'getRoles', 'createCode', 'getPlatformFlavors']
		),
		updateData({ newVal }) {
			this.data = newVal;
		},
		save() {
			this.$refs.form.reportValidity();
			if (this.$refs.form.checkValidity()) {
				this.data.applications = this.associated?.applications?.map(a => a.name) || [];
				this.data.removedScopes = this.associated?.removedScopes || [];
				this.data.organizations = this.associated?.organizations?.map(o => o._id) || [];
				this.data.role = this.selectedRole;

				const propertiesToPick = ['_id', 'code', 'description', 'disabled', 'platformFlavor'];
				if (this.access.scopes.includes('organization:read'))
					propertiesToPick.push('organizations');
				if (this.access.scopes.includes('application:read'))
					propertiesToPick.push('applications', 'removedScopes');
				//if (this.access.scopes.includes('role:read'))
					propertiesToPick.push('role');
				const patch = pick(propertiesToPick, this.data);

				if(this.id) {
					this.updateCode(patch)
						.then(result => {
							this.data = result;
							this.successToast(this.$gettext('Code updated'));
						})
						.catch(this.errorToast);
				}else{
					this.createCode(patch)
						.then(result => {
							this.data = result;
							this.successToast(this.$gettext('Code created'));
						})
						.catch(this.errorToast);
				}
			}
		},
		back() {
			this.$router.push('/codes');
		},
		async loadAsyncData() {
			this.isLoading = true;
			const params = {
				sortBy: 'name|asc',
				isService: false,
			};
			if(!this.id){
				this.data = {
					applications: [],
					organizations: [],
					removedScopes: []
				}
			}
			const promises = [];
			if (this.id && this.access.scopes.includes('code:read'))
				promises.push(
					this.getCode(this.id)
						.then(data => {
							this.data = data;
						})
						.catch(err => {
							this.data = undefined;
							this.errorToast(err);
						}),
				);
			if (this.access.scopes.includes('application:read'))
				promises.push(
					this.getApplications(params)
						.then(data => {
							this.allApplications = data?.results || [];
						})
						.catch(err => {
							this.allApplications = [];
							this.errorToast(err);
						}),
				);
			if (this.access.scopes.includes('organization:read'))
				promises.push(
					this.getOrganizations({ sortBy: 'name|asc' })
						.then(data => {
							this.allOrganizations = data?.results || [];
						})
						.catch(err => {
							this.allOrganizations = [];
							this.errorToast(err);
						}),
				);

			if (this.access.scopes.includes('role:read'))
				promises.push(
					this.getRoles({ sortBy: 'name|asc' })
						.then(data => {
							this.allRoles = data?.results || [];
						})
						.catch(err => {
							this.allRoles = [];
							this.errorToast(err);
						}),
				);

			await Promise.all(promises);

			if (this.access.scopes.includes('application:read')) {
				this.unassociated.applications = this.data.applications
					? this.allApplications.filter(item => !this.data?.applications?.includes(item.name))
					: this.allApplications;
				this.associated.applications = (this.data?.applications || []).map(a =>
					this.allApplications.find(aa => aa.name === a),
				);
				this.determineScopes();
			}
			if (this.access.scopes.includes('organization:read')) {
				this.unassociated.organizations = this.data.organizations
					? this.allOrganizations.filter(item => !this.data?.organizations?.includes(item._id))
					: this.allOrganizations;
				this.associated.organizations = (this.data.organizations || []).map(organizationId =>
					this.allOrganizations.find(ao => ao._id === organizationId),
				);
			}
			if (this.access.scopes.includes('role:read')) {
				this.selectedRole = this.data.role
			}

			if (this.access.scopes.includes('code:read'))
				promises.push(
					this.getPlatformFlavors({ sortBy: 'name|asc' })
						.then(data => {
							this.platformFlavors = data?.results || [];
						})
						.catch(err => {
							this.platformFlavors = [];
							this.errorToast(err);
						}),
				);

			this.isLoading = false;
		},
		handleChangeApplications({ associated, unassociated }) {
			this.associated.applications = associated;
			this.unassociated.applications = unassociated;
			this.determineScopes();
		},
		handleChangeScopes({ associated, unassociated }) {
			this.associated.removedScopes = associated;
			this.unassociated.removedScopes = unassociated;
		},
		handleChangeOrganizations({ associated, unassociated }) {
			this.associated.organizations = associated;
			this.unassociated.organizations = unassociated;
		},
		removeScopes() {
			this.associated.removedScopes = [];
		},
		determineScopes() {
			this.allScopes = uniq(
				this.associated.applications
					.filter(n => n)
					.map(app => app.scopes)
					.reduce(concat, []) || [],
			);
			this.unassociated.removedScopes = this.data.removedScopes
				? this.allScopes.filter(item => !this.data.removedScopes.includes(item))
				: this.allScopes;
			this.associated.removedScopes = this.data.removedScopes || [];
		},
	},
};
</script>
