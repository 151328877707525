<template>
	<section>
		<h1 v-translate class="is-size-1">Registration codes</h1>
		<div class="columns">
			<div class="column is-half is-mobile">
				<!-- buttons that only visible when some rowes are checked -->
				<div v-if="checkedRows.length !== 0" class="buttons">
					<b-button type="is-danger is-outlined" icon-left="delete" @click="confirmDelete">
						<translate>Delete</translate>
					</b-button>
					<span class="pb-1">
						<translate
							:translate-n="checkedRows.length"
							:translate-params="{ total: checkedRows.length }"
							translate-plural="%{total} selected"
						>
							%{total} selected</translate
						>
					</span>
				</div>
			</div>
			<div class="column is-half is-mobile">
				<!-- buttons that are always visible -->
				<div class="buttons is-right">
					<b-button
						v-if="access.scopes.includes('code:write')"
						icon-left="plus" type="is-primary" tag="router-link" to="/code">
						<translate>New code</translate>
					</b-button>
				</div>
			</div>
		</div>
		<b-table
			ref="codeTable"
			checkable
			striped
			hoverable
			:data="data"
			:loading="loading"
			paginated
			:current-page.sync="page"
			backend-pagination
			backend-sorting
			backend-filtering
			:total="total"
			:per-page="perPage"
			:checked-rows.sync="checkedRows"
			:aria-next-label="$gettext('Next page')"
			:aria-previous-label="$gettext('Previous page')"
			:aria-page-label="$gettext('Page')"
			:aria-current-label="$gettext('Current page')"
			:default-sort-direction="defaultSortOrder"
			:default-sort="[sortField, sortOrder]"
			:debounce-search="250"
			@sort="onSort"
			@page-change="onPageChange"
			@filters-change="onFilter"
		>
			<b-table-column field="name" :label="$gettext('Code')" sortable searchable>
				<template #searchable="props">
					<b-input v-model="props.filters.code" :placeholder="$gettext('Search')" icon="magnify" />
				</template>
				<template #default="props">
					<router-link :to="`/code/${props.row._id}`" tag="span" class="is-clickable">
						{{ props.row.code }}
					</router-link>
				</template>
			</b-table-column>

			<b-table-column v-slot="props" field="totalHospitals" :label="$gettext('Hospitals')" sortable>
				<router-link :to="`/code/${props.row._id}`" tag="span" class="is-clickable">
					{{ props.row.totalHospitals }}
				</router-link>
			</b-table-column>

			<b-table-column v-slot="props" field="totalApps" :label="$gettext('Apps')" sortable>
				<router-link :to="`/code/${props.row._id}`" tag="span" class="is-clickable">
					{{ props.row.totalApps }}
				</router-link>
			</b-table-column>

			<b-table-column v-slot="props" field="totalScopes" :label="$gettext('Scopes')" sortable>
				<router-link :to="`/code/${props.row._id}`" tag="span" class="is-clickable">
					{{ props.row.totalScopes }}
				</router-link>
			</b-table-column>

			<template slot="bottom-left">
				<b-select :value="perPage" @input="onPerPageChange">
					<option v-for="value in [20, 50, 100]" :key="value" v-translate="{ value }" :value="value">
						%{value} per page
					</option>
				</b-select>
			</template>
		</b-table>
	</section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { omit, equals } from 'ramda';
import { humanTime, localDate, toAge, formatPhoneNumber, formatPhoneUri } from '@moveup/app-core/filters.js';
import { errorToast, successToast } from '@moveup/app-core/mixins';

export default {
	filters: {
		humanTime,
		localDate,
		toAge,
		formatPhoneNumber,
		formatPhoneUri,
	},
	mixins: [errorToast, successToast],
	data() {
		return {
			data: [],
			total: 0,
			checkedRows: [],
			loading: false,
			sortField: 'code',
			sortOrder: 'asc',
			defaultSortOrder: 'asc',
			page: 1,
			filter: {
				name: '',
			},
		};
	},
	computed: {
		...mapState('auth', ['access']),
		...mapState('common', ['perPage']),
	},
	mounted() {
		// Parse page number from url
		if (this.$route.params.page) this.page = parseInt(this.$route.params.page, 10);

		// Parse sorting query from url
		if (this.$route.query.sortField) this.sortField = this.$route.query.sortField;
		if (this.$route.query.sortOrder) this.sortOrder = this.$route.query.sortOrder;

		// Parse filter from url
		const filter = omit(['sortField', 'sortOrder'], this.$route.query);
		this.$set(this, 'filter', filter);
		Object.keys(filter).forEach(key => {
			this.$set(this.$refs.codeTable.filters, key, filter[key]);
		});
		this.loadAsyncData();
	},
	methods: {
		...mapActions('app', ['getCodes', 'deleteCodes']),
		...mapActions('common', ['setPerPage']),
		loadAsyncData() {
			Object.keys(this.filter).forEach(key => {
				if (!this.filter[key]) delete this.filter[key];
			});

			const query = { sortField: this.sortField, sortOrder: this.sortOrder, ...(this.filter || {}) };

			if (!equals(query, this.$route.query))
				this.$router.push({
					path: this.$route.path,
					query,
				});

			const params = {
				sortBy: `${this.sortField}|${this.sortOrder}`,
				page: this.page,
				perPage: this.perPage,
				...this.filter,
			};

			this.checkedRows = [];
			this.loading = true;

			this.getCodes(params)
				.then(data => {
					this.data = data.results;
					this.total = data.total;
					this.loading = false;
				})
				.catch(err => {
					this.data = [];
					this.total = 0;
					this.loading = false;
					this.errorToast(err);
				});
		},
		confirmDelete() {
			const total = this.checkedRows.length;
			const translated = this.$ngettext(
				'Are you sure you want to delete this code? This action cannot be undone.',
				'Are you sure you want to delete these %{total} codes? This action cannot be undone.',
				total,
			);
			const message = this.$gettextInterpolate(translated, { total });
			this.$buefy.dialog.confirm({
				title: this.$gettext('Sure?'),
				message,
				confirmText: this.$gettext('Delete code'),
				type: 'is-danger',
				hasIcon: true,
				onConfirm: this.deleteSelected,
				focusOn: 'cancel',
			});
		},
		deleteSelected() {
			const ids = this.checkedRows.map(r => r._id);
			this.deleteCodes(ids).then(result => {
				const total = result.deletedCount;
				const translated = this.$ngettext('%{total} code deleted.', '%{total} codes deleted.', total);
				const message = this.$gettextInterpolate(translated, { total });
				this.successToast(message);
				this.loadAsyncData();
			});
		},
		onPerPageChange(value) {
			this.page = 1;
			// Save perPage in the store, because it's good that this piece of UI info is persisted
			this.setPerPage(value);
			if (this.$route.path !== '/codes') this.$router.push(`/codes`);
			this.loadAsyncData();
		},
		onPageChange(page) {
			this.page = page;
			this.$router.push(`/codes/page/${page}`);
			this.loadAsyncData();
		},
		onSort(field, order) {
			this.sortField = field;
			this.sortOrder = order;
			this.loadAsyncData();
		},
		onFilter(filter) {
			this.filter = filter;
			this.loadAsyncData();
		},
	},
};
</script>
